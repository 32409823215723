body.base .custom-toggle-group {
    border-radius: 4px !important;
    margin: 0;
    .mat-button-toggle {
        margin: 0;
        border-radius: 4px !important;
        border: 1px solid #4d5acf !important;
        &:hover {
            background-color: #4d5acf;
            .mat-button-toggle-label-content {
                color: white !important;
            }
        }
    }
    .mat-button-toggle .mat-button-toggle-label-content {
        border-radius: 4px !important;
    }
    .mat-button-toggle-checked .mat-button-toggle-label-content {
        color: white !important;
    }
    .mat-button-toggle-checked {
        background-color: #4f46e5;
        margin: 0;
    }
}

body.base .toggle-group {
    border: 1px solid rgb(203 213 225 / var(--tw-border-opacity)) !important;
    border-radius: 4px !important;
    margin: 0;
    .mat-button-toggle {
        margin: 0;
        border-radius: 4px !important;
        border-left: solid 1px #e2e8f0 !important;
    }
    .mat-button-toggle .mat-button-toggle-label-content {
        padding: 0 10px;
    }
}
