body.base .stepper-link {
    background-color: inherit !important;

    .mat-horizontal-stepper-header-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0 16px;
        background: #031a6e;
        color: whitesmoke;
        margin-bottom: 0;

        .mat-step-label {
            color: whitesmoke;
            padding: 0;
        }
        .mat-step-label.mat-step-label-active {
            color: whitesmoke;
            padding: 0;
        }
        .mat-stepper-horizontal-line {
            height: 2px;
        }

        .mat-step-icon {
            background-color: inherit;
            border: 1px solid white;
        }

        .mat-step-icon-selected {
            background-color: #64748b !important;
        }
    }
    .mat-horizontal-content-container {
        padding: 0;
    }

    .mat-step-text-label {
        font-size: 10px;
        font-weight: 500;
    }
}

body.base .mat-stepper-vertical {
    .mat-vertical-content {
        padding: 0.75rem;
    }
}

body.base .stepper-link-hide {
    background-color: inherit !important;

    .mat-horizontal-stepper-header-container {
        display: none;
    }
}

body.base .stepper-create-link {
    .mat-horizontal-stepper-header-container {
        .mat-horizontal-stepper-header {
            padding: 0px 2px 12px 2px !important;
        }

        .mat-step-label {
            padding: 4px 0 0 0 !important;
        }
    }

    .mat-stepper-horizontal-line {
        top: 12px;
    }
    /* background-color: inherit !important;

    .mat-horizontal-stepper-header-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0 16px;
        background: #031a6e;
        color: whitesmoke;
        margin-bottom: 0;

        .mat-step-label {
            color: whitesmoke;
            padding: 0;
        }
        .mat-step-label.mat-step-label-active {
            color: whitesmoke;
            padding: 0;
        }
        .mat-stepper-horizontal-line {
            height: 2px;
        }

        .mat-step-icon {
            background-color: inherit;
            border: 1px solid white;
        }

        .mat-step-icon-selected {
            background-color: #64748b !important;
        }
    }
    .mat-horizontal-content-container {
        padding: 0;
    }

    .mat-step-text-label {
        font-size: 10px;
        font-weight: 500;
    } */
}
