body.base .app-table {
    thead,
    thead tr {
        height: 2.8rem;
        th {
            padding: 0 8px;
            height: 2.8rem;
            .mat-sort-header-arrow {
                color: currentColor;
            }
        }
        .th-text {
            min-width: 10rem;
            max-width: 15rem;
            width: auto;
        }
        .column-action {
            min-width: 5rem;
            max-width: 5rem;
            width: auto;
            .mat-sort-header-container {
                justify-content: center;
            }
        }
    }

    tbody > tr {
        height: 3rem;
        vertical-align: inherit;
        td {
            padding: 0 8px;
            height: 3rem;
            //border: 1px #b2bac6 solid;
            vertical-align: inherit;
            .truncate-text {
                min-width: 10rem;
                max-width: 15rem;
                width: auto;
                max-height: 3.125rem;
                height: auto;
                overflow: hidden;
                p {
                    /* word-break: break-all; */
                    margin: 0;
                }
            }
            .truncate-text:hover {
                overflow: auto;
            }
        }
        .body-action {
            text-align: center;
        }
    }

    tbody .selected {
        .fixed-column {
            background: rgba(15, 84, 140, 0.1);
        }
        background-color: rgba(15, 84, 140, 0.1);
    }

    .mdc-data-table__row:hover {
        .fixed-column {
            background: rgb(246, 245, 245) !important;
        }
        background-color: rgba(128, 128, 128, 0.05) !important;
    }
}

body.base .table-checkbox {
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
            [data-indeterminate="true"]
        )
        ~ .mdc-checkbox__background {
        background-color: whitesmoke;
    }
}
