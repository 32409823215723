body.base .filtered-search-box {
    .custom-select {
        .mat-mdc-text-field-wrapper {
            border-radius: 6px 0 0 6px;
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
            padding: 0;
            margin: 0;
        }

        .mat-mdc-form-field-infix {
            width: auto !important;
        }

        .mat-mdc-select-trigger {
            width: 100% !important;
        }
        .mat-mdc-select-value-text {
            color: rgba(
                var(--fuse-text-secondary-rgb),
                var(--tw-text-opacity)
            ) !important;
        }
    }

    .search-field {
        .chip-container {
            display: flex;
            height: 40px;
            width: auto;
            max-width: 100%;
            overflow-x: auto;

            .mdc-evolution-chip-set__chips {
                display: flex;
                flex-wrap: nowrap;
                white-space: nowrap;
                align-items: center;
                padding-left: 16px;
            }
        }

        .mat-mdc-chip.mat-mdc-standard-chip {
            height: 26px;
        }
        .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
            padding: 2px 7px;
        }

        .chip-container::-webkit-scrollbar {
            height: 8px;
            /* position: absolute;
            bottom: -10px; 
            left: 0;
            width: 100%;
            z-index: 99999999; */
        }

        .mat-mdc-text-field-wrapper {
            border-radius: 0 0px 0px 0;
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
            padding: 0;
            margin: 0;
        }

        .mat-mdc-autocomplete-panel {
            min-width: 20%;
            min-width: 80%;
            width: auto;
        }

        .mat-mdc-form-field-infix {
            //justify-content: space-between;
        }
    }
}

body.base .cdk-overlay-pane {
    //width: auto !important;
}

body.base .drop-menu {
    max-width: 500px;

    .mat-mdc-menu-item {
        border-bottom: 1px solid;
        border-color: inherit;
        margin-bottom: 4px;
        span {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }
    }
}

body.base .mat-mdc-autocomplete-panel {
    width: 200px;
}

body.base .mat-mdc-chip {
    margin-right: 1px;
    background-color: #f3f4f6;
}
