body.base .demo-btn {
    border: none;
    outline: none;
    position: relative;
    background: linear-gradient(180deg, #cbd5e1 25%, #23937a 40%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24),
        0 8px 16px 0 rgba(0, 0, 0, 0.19);
}

body.base .demo-btn::before {
    content: "";
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: #32c0a0;
    border-radius: 9999px;
}

body.base .demo-next:disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
