body.base .mat-mdc-select-panel-above {
    width: 160px !important;
}

body.base .multi-chips {
    /* .mat-mdc-floating-label {
        top: calc(
            50% - 20px
        ); 
        transition: top 0.2s ease-in-out; 
    } */

    /* Cuando el contenedor crezca a 80px */
    .mat-mdc-form-field-flex:has(.mat-chip) .mat-mdc-floating-label {
        top: calc(50% - 40px); /* Ajusta para contenedores de 80px */
    }

    .mat-mdc-form-field-infix {
        width: 42px;
        .mat-mdc-select {
            .mat-mdc-select-value-text {
                display: none;
            }
        }
    }
    .mat-mdc-form-field-icon-prefix {
        width: 100%;
        overflow: auto;
        height: auto;
        max-height: 80px;
        min-height: 40px;
        padding: 4px;
    }
    .mat-mdc-form-field-flex,
    .mat-mdc-text-field-wrapper {
        height: auto !important;
        min-height: 40px;
        max-height: 80px;
        align-items: center;
    }
}
